.dashboardWidget {
  container-type: size;
}

.dashboardWidgetTopBarSubtitle {
  display: none;
}

@container (min-width: 450px) and (min-height: 300px) {
  .dashboardWidgetTopBarSubtitle {
    display: block;
  }
}
